import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./Programs.css";
import imageProgramList from "../../images/bg-program-list.png";
import {AddCircle, AddSquare, ArrowSquareRight} from "iconsax-react";
import {Link, useNavigate} from "react-router-dom";
import OrderApi from "../../services/OrderApi";
import DialogAlert from "../../components/DialogAlert/DialogAlert";
import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";
import imageProgramGym from "../../images/bg-program-gym.png";
import imageProgramHome from "../../images/bg-program-home.png";
import imageProgramChampion from "../../images/bg-program-champion.png";
import imageProgramFood from "../../images/bg-program-food.png";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import General from "../../utils/General";

//--------------------------------------------------

function Programs(props)
{
    const { t, i18n } = useTranslation(); // translator

    // set page title & page path
    usePage().pageData({title: t(Menus.programs.title), activeMenu: Menus.programs.className});

    //
    const navigate = useNavigate();
    const dialogAlert = useRef(); // init dialog alert

    const [programDataLoading, setProgramsDataLoading] = useState(true);
    const [programData, setProgramsData] = useState(null);

    // load data
    const loadData = () =>
    {
        setProgramsDataLoading(true); // show loading

        // get data
        OrderApi.list({language: General.getLanguage(i18n.language)}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setProgramsData(response.data.list);
                }

                setProgramsDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProgramsDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    const handleGoToShowOrder = (index) =>
    {
        switch (programData[index].status)
        {
            case 'Pending': dialogAlert.current.show(t('Pending'), t('PendingDes'), "warning"); break;
            case 'Reject': dialogAlert.current.show(t('Rejected'), t('RejectedDes'), "error"); break;
            case 'Completed': navigate("/program/" + (programData[index].isFood ? "food" : "workout") + "/" + programData[index].id, { replace: false }); break;
        }
    };

    //
    return (
        <div className="page-content page-programs">
            <div className="section-title">
                <div className="title-box">{parse(t('MyPrograms'))}</div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {/*<div className="page-description">{parse(t('MyProgramsDes'))}</div>*/}
                {programData == null && !programDataLoading ? <AlertDataEmpty des={parse(t('YouCurrentlyDoNotHaveAnyPrograms'))} /> : null}

                <div className="program-items">
                    <div className="container">
                        <div className="row">
                            {
                                programData != null && programData.length > 0 ?
                                    programData.map
                                    (
                                        (program, i) =>
                                            <div className="col-4">
                                                <div className="item" onClick={() => handleGoToShowOrder(i)}>
                                                    <div className="image-title-des">
                                                        <img src={program.image} />
                                                        <div className="title">{program.title}</div>
                                                        {/*{*/}
                                                        {/*    {*/}
                                                        {/*        0: program.foodWorkout === 0 ? <img src={imageProgramFood} /> : <img src={imageProgramHome} />,*/}
                                                        {/*        1: <img src={imageProgramGym} />,*/}
                                                        {/*        2: <img src={imageProgramChampion} />,*/}
                                                        {/*    }[program.trainingAt]*/}
                                                        {/*}*/}
                                                        {/*<div className="title">*/}
                                                        {/*    {*/}
                                                        {/*        {*/}
                                                        {/*            0: t('TrainingProgramHome2'),*/}
                                                        {/*            1: t('TrainingProgramGym2'),*/}
                                                        {/*            2: t('TrainingProgramChampions2'),*/}
                                                        {/*        }[program.trainingAt]*/}
                                                        {/*    }*/}
                                                        {/*</div>*/}
                                                        <div className="des">{program.foodWorkout === 0 ? t('FoodProgram2') : t('WorkoutProgram2')}<br />{t('OnlineSupport')}{/*<br />2 Month training program<br />Online support*/}</div>
                                                    </div>

                                                    <div className="price-box">{t('ShowMyProgram')}<ArrowSquareRight className="icon" variant="Bulk" /></div>
                                                    {/*<button type="button" className="btn-custom t2 full" onClick={() => handleGoToShowOrder(i)}>{parse(t('ShowMyProgram'))}<ArrowSquareRight className="icon" variant="Bulk" /></button>*/}
                                                </div>
                                            </div>
                                    )
                                    :
                                    null
                            }

                            <div className="col-4">
                                <Link to="/new-program" className="item new">
                                    <div className="title">{parse(t('AddNewProgram2'))}</div>
                                    <AddCircle className="icon" variant="Bulk" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* loading */}
                {programDataLoading ? <LoadingOver /> : null}

                {/* dialog alert */}
                <DialogAlert ref={dialogAlert} />
            </Scrollbars>
        </div>
    );
}

export default Programs;
