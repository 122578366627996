import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useState} from "react";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./ProgramFood.css";
import OrderApi from "../../services/OrderApi";
import {useParams} from "react-router";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {DocumentDownload} from "iconsax-react";
import parse from 'html-react-parser';
import {useTranslation} from "react-i18next";
import General from "../../utils/General";

//--------------------------------------------------

function ProgramFood(props)
{
    // get id
    let { orderId } = useParams();

    const { t, i18n } = useTranslation(); // translator

    // set page title & page path
    usePage().pageData({title: t(Menus.programFood.title), activeMenu: Menus.programFood.className});

    //
    const [programDataLoading, setProgramDataLoading] = useState(true);
    const [programData, setProgramData] = useState(null);

    // load data
    const loadData = () =>
    {
        setProgramDataLoading(true); // show loading

        // get data
        OrderApi.show(orderId > 0 ? orderId : 0, {'language': General.getLanguage(i18n.language), 'order-type': 'food'}).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setProgramData(response.data);
                }

                setProgramDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProgramDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // download pdf
    const downloadPdf = () =>
    {
        setProgramDataLoading(true); // show loading

        // get file
        OrderApi.pdf(orderId > 0 ? orderId : 0, {'language': General.getLanguage(i18n.language), 'order-type': 'food'}).then
        (
            function(response)
            {
                const blob = new Blob([response], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'program-food-' + (orderId > 0 ? orderId : 0) + '.pdf';
                link.click();

                setProgramDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProgramDataLoading(false); // hide loading
            }
        );
    };

    //
    return (
        <div className="page-content page-program-food">
            <div className="section-title">
                <div className="title-box">{parse(t('FoodPrograms'))}</div>

                {programData != null && programData.program != null ? <button className="btn-custom t2 mini" type="button" onClick={downloadPdf}><DocumentDownload className="m-r-8" variant="Bulk" />{parse(t('DownloadPdf'))}</button> : null}
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    programDataLoading ?
                        null
                        :
                        (
                            programData != null && programData.program != null ?
                                <>
                                    <div className="page-description">{programData.description}</div>
                                    {
                                        programData.program != null && programData.program.length > 0 ?
                                            <table className="table-data-t2">
                                                <thead>
                                                <tr>
                                                    <th>{t('Stephan')}</th>
                                                    <th>{t('Time')}</th>
                                                    <th>{t('Diet')}</th>
                                                    <th>{t('Supplements')}</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    programData.program.map
                                                    (
                                                        (program, i) =>
                                                            <tr>
                                                                <td>{program.stephan}</td>
                                                                <td>{program.time}</td>
                                                                <td>{program.diet}</td>
                                                                <td>{program.supplement}</td>
                                                            </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                            :
                                            null
                                    }
                                </>
                                :
                                <AlertDataEmpty />
                        )
                }

                {/* loading */}
                {programDataLoading ? <LoadingOver /> : null}
            </Scrollbars>
        </div>
    );
}

export default ProgramFood;
