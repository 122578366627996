import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
// routers
import RouterPublic from "./RouterPublic";
import RouterPrivate from './RouterPrivate';
// pages
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import Profile from "../pages/Profile/Profile";
import Chat from "../pages/Chat/Chat";
import CoolDown from "../pages/CoolDown/CoolDown";
import WarmUp from "../pages/WarmUp/WarmUp";
import NewProgram from "../pages/Program/NewProgram";
import Programs from "../pages/Program/Programs";
import ProgramFood from "../pages/Program/ProgramFood";
import ProgramWorkout from "../pages/Program/ProgramWorkout";
import PageNotFound from '../pages/PageNotFound';

//--------------------------------------------------

function Router()
{
    return (
        <Routes>
            <Route element={<RouterPublic />}>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
            </Route>

            <Route exact path='/' element={<Navigate to="/programs" />} />

            <Route element={<RouterPrivate />}>
                <Route path="/programs" element={<Programs />} />
                <Route path="/new-program" element={<NewProgram />} />

                <Route path="/program/food" element={<ProgramFood />} />
                <Route path="/program/food/:orderId" element={<ProgramFood />} />

                <Route path="/program/workout" element={<ProgramWorkout />} />
                <Route path="/program/workout/:orderId" element={<ProgramWorkout />} />

                <Route path="/chat" element={<Chat />} />

                <Route path="/cool-down" element={<CoolDown />} />

                <Route path="/warm-up" element={<WarmUp />} />

                <Route path="/profile" element={<Profile />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default Router;
